import React from "react";
import CommunityWrite from "../../../components/CommunityWrite";

const CommunityBunriWrite = () => {
  return (
    <div>
      <CommunityWrite posttype="bunri" />
    </div>
  );
};
export default CommunityBunriWrite;
