import React from "react";
import CommunityWrite from "../../../components/CommunityWrite";

const CommunityNanumWrite = () => {
  return (
    <div>
      <CommunityWrite posttype="nanum" />
    </div>
  );
};

export default CommunityNanumWrite;
