import React from "react";
import WikiDetailForm from "./WikiDetailForm";

const UserUpdateRequestInfo = () => {
  return (
    <div>
      <WikiDetailForm type="user" />
    </div>
  );
};

export default UserUpdateRequestInfo;
