import React from "react";
import SolutionList from "../../components/SolutionList";
const WikiTotalList = () => {
  return (
    <div>
      <SolutionList type="totalWiki" />
    </div>
  );
};

export default WikiTotalList;
